/* Mobile Styling ************************************* */
.footer{
    display: block;
    position: fixed;
    bottom:5rem;
    width:100vw;
    overflow-wrap: normal;
    overflow-x: visible;
    /* word-wrap: break-word; */
}
.footer-times{
    /* border:1px dashed red; */
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height:112px;
    overflow-y:hide;
    z-index: -1;
}
.mf-item{
    background: #eef; 
    margin:5px;
    font-size:1.1rem;
    padding:2px;
    border-radius: 3px;
    z-index: -1;
}

.footer .footer-stats{
    display:block;
    /* position:relative; */
    height:100%;

    background: #23558B;
    color: black;
    margin:0;
    padding:0;  
    text-align:center;  

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:2rem;
}


.top{
    background: #23558B;
    color: white;
    font-size: 1.4rem;
    padding-bottom: 0.5rem;
}

.upper-left{
    display: inline-block;
    color:white;
    background: #9b9a9a;
    width:50%;
    margin:0;
    padding:0;
    max-height:4.125rem;
    /* border-radius:5px; */
}
.upper-right{
    display: inline-block;
    width: 50%;
    color:white;
    background: #558B23;
    margin:0;
    padding:0;    
    max-height:4.125rem;
}
.lower-left{
    display: inline-block;
    color:white;
    background: #558B23;
    width:50%;
    margin:0;
    padding:0;    
    max-height:4.125rem;
    overflow-y:hidden;

}
.lower-right{
    display: inline-block;
    width: 50%;
    color:white;
    background: #9b9a9a;
    margin:0;
    padding:0;
    max-height:4.125rem;

}

.footer-value{
    font-size: 2rem;
    border-bottom: 2px solid black;
    width: 75%;
    margin: 0 auto;
    padding:0;
}

.footer-value-smaller{
    font-size:2rem;
}

.footer-caption{
    font-size: 1rem;
    padding:0;
    margin:0;
}

.footer-buttons{
    display:block;
    width:100%;

    position:fixed;
    bottom:0;
    left:0;
    
    background:#23558B;
    

    padding-top:1rem;
}

.footer-button{
    width: 25%;
    height:4rem;
    font-weight: bold;
    font-size: 1.1rem;
    margin:0;
    padding:0;

    box-shadow:inset 0 -0.6em 0 -0.35em rgba(45,45,45,0.17);
    border-radius: 0;
}
  


/* iPhone X in horizontal mode */
@media(max-height: 400px){

    .footer .footer-stats{
        display:none;
    }
}
  
  /* Desktop Styling ********************************** */
  @media (min-width: 52rem) {
    .footer{
      display: none;
    }
  }
  


  
  