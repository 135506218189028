
.dataui-histogram{

  /* background: rgb(98, 149, 190); */
  position:relative;
  /* top:10px; */
  width: 400px;
  margin: auto;
  vertical-align: middle;
  overflow:hidden;
  background: #ccc;
}

/* .dataui-histogram svg{
  text-align:center;
  margin: 0 auto;
  padding:0;
  vertical-align: middle;
  
} */

