.timelist{
  position:relative;
  top:0;
  left:0;
  display: block;
  background: #1D5775;
  
  /* border:0.5px dotted red; */
  height:100%;
  width:100%;
  margin-top:0.5rem;

  overflow:scroll;
}
.timelist::-webkit-scrollbar { 
  width: 0 !important 
}

.timelist-info{
  background: #1D5775;
  color:#eee;
  /* padding-top:5rem; */
  padding: 1rem 1rem 0 1rem;
  text-align: center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.5rem;
  border:1px dashed yellow;
}

.timelist-info ul{
  list-style: none;
  background: #eee;
  color:black;
  text-align: left;
  font-size: 1rem;
  padding: 0 0 1rem 0;
}

.table-timelist{

  table-layout: fixed;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1rem;
  width:100%;
    
  border-collapse: separate;
  border-spacing: 0px;
  min-width: 12.6rem;
  white-space:nowrap;
  text-align: center;

  color: black;
  background: #aaa;
  position: absolute;
  top:0;
  left:0;
}


.table-timelist__body-header-item{
  background: #bbb;
  color: black;
  border:1px dashed green;
  position: sticky;
  top:0;
  z-index:4;  
}

.table-timelist__body .table-timelist__row{

  /* vertical-align: inherit; */
  color:white;
  background: #666;

}
.table-timelist__row-item{
  width:100%;
  padding: 0.2rem;
  border: 1px dashed #aaa;
}

.table-timelist__row-id{
  width:100%;
  padding: 0.2rem;
  border: 1px dashed #aaa;
  border-right: 5px solid gray;
}

.table-timelist__row-id_target{
  width:100%;
  padding: 0.2rem;
  border: 1px dashed #aaa;
  border-right: 5px solid yellowgreen;
  
}
.target-met{
  border-right: 5px solid greenyellow;
  /* text-emphasis: white 'o'; */

}

.first{
  color: white;
  border: 1px dashed yellow;
  /* background:black; */
}

.penalty{

  color: #fee;
  border: 2px dotted red;
  background: red;
}
.dnf{
  color:white; 
  background:black;
}

.table-timelist__row-item:hover{
  background:#ccc;
  color:blue;
}

.table-timelist__row-item-highlight{
  /* background: yellow;
  color: black; */
  color:yellow;
  border: 2px solid yellow;
}

.table-timelist__row-item-highlight:hover{
  background:#ccc;
  color:blue;
}

.table-timelist__row:nth-child(odd){

background: #867;
}





