.stats-time {
  display: inline-block;
}

ul.stats-list {
  list-style: none;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background: #44c;
  border-radius: 2px;
  color: rgb(231, 231, 0);
  text-align: center;
}
.stats-tables {
  /* position: relative;
    width:100%; */
  position: absolute;
  left: 0;
  bottom: 74%;
  width: 100%;
  background: #666;
}

.stats-table {
  width: 100%;
  /* border: 1px dashed white; */
  text-align: center;
  background:#1d4774; /* #55d;*/
  margin-bottom: 0.3rem;
}
.stats-table-header {
  background: #1d4774;/* #33b;*/
  color: #ddd;
  font-size: 0.9rem;
}

.stats-table-item {

  background: #bbe;
  color: black;

  font-size: 1rem;
}

.stats-table-header.fast{
    color: rgb(250, 238, 77);
  }

  
@media (max-height: 420px) {
  .stats-table {
    display: none;
  }
}
