.backdrop{
    display: fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    background: rgba(0,0,0,0.618);
    z-index:5;
    cursor: pointer;

}

.modal{
    display: absolute;
    top:0;
    left:0;
    height:100vh;
    width:25%;
    background: #ddd;
    z-index:6;
    cursor: pointer;
    
}

.modal-menu{
    list-style: none;
    margin:0;
    padding:0;
    background: #ccc;
    height: 30rem;

    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-content: center;
    align-items: center;
    
}

.modal-menu-item{
    background: #eee;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size:1.8rem;

    width:90%;
    text-align:center;
    padding: 0.5rem;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.7);
    border-radius: 5%;



}

.modal-menu-item:hover,
.modal-menu-item:active{
    background: #666;
    color:white;
}

.modal-menu-header{


    background: #1D5775;
    color: #ff0;
    padding: 1rem;
    width:100%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 2.3rem;
    text-align:center;
    /* margin-top: -20px; */
}

.desktop-info{
    /* background: #1D5775; */
    color:black;
    /* padding-top:5rem; */
    padding: 1rem 1rem 0 1rem;
    text-align: center;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1.5rem;
    /* border:1px dashed yellow; */
  }
  
  .desktop-info ul{
    list-style: none;
    background: #eee;
    color:black;
    text-align: left;
    font-size: 1rem;
    padding: 0 0 1rem 0;
  }
  