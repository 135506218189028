* {
  box-sizing: border-box; /* tells the browser to account for any border and padding in the values you specify for an element's width and height. */
}

html {
  background: black;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
.overlay {
  padding: 0;
  /* margin: 2rem 0; */
}

/* @media screen {
  .overlay {
    padding: 3rem 0;

  }
} */

main {
  display: static;
  margin: 0;
  padding: 0;
  background: white;
  width: 100vw;
  height: 100vh;
}

.container-left {
  position: relative;
  top: 0;
  display: none;
  background: #1D5775;
  width: 25%;
  vertical-align: top;
  height: 100vh; /*100%;*/
  margin: 0;
  overflow: hidden;
}

.container-right {
  background: linear-gradient(to bottom, mintcream 55%, blue 70%);
  background:#D6E5F5;
  width: 100vw;
  /* display: inline-block; */
  vertical-align: top;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.identity {
  background: #1D5775;
  padding: 0;
  /* border:1px dashed yellow; */
}

.list {
  display: block;
  background: #1D5775;
  padding: 0px;
  height: 65%;
  overflow: hidden;
}

.scramble {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-size: 2rem;
  background: #1d4774;
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
  letter-spacing: 0.75px;
  word-spacing: 6px;
  overflow-y: auto;
  white-space: normal;
}

.scramble-text {
  font-size: 2rem;
  margin: 0;
  padding: 1.5rem;
}

.timer {
  display: flex;
  justify-content: center;
  align-content: center;

  height: 7rem;
  /* min-height: 10rem; */
  flex-grow: 1;

  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 6rem;
  color: midnightblue;
  background-color: mintcream;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: pointer;
}
.timer-timecard {
  align-self: center;
  display: inline;
}

.timer hide {
  display: none;
}

.stats {
  background: #1f1e74;
  color: white;
  padding: 5px;
  height: 100%;
}

.summary {
  display: none;
}

.charts {
  display: none;
}

/* Desktop Styling ********************************** */

@media (min-width: 52rem) {
  .container-left {
    display: inline-block;
  }

  .container-right {
    width: 75%;
    display: inline-block;

    border-left: 1rem solid black;
  }

  .container-right-flex {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    background: mintcream;
    /* background: linear-gradient(mintcream 40%, #1d4774); */
    /* align-items: center; */
    /* align-content: space-around; */
  }

  .scramble {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 10rem;
    min-height: 10rem;
  }

  .scramble-text {
    font-size: 2.5rem;
    margin: 0;
    padding: 1.5rem;
  }

  .timer {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 1.5rem;
    margin: 0;
    height: 12rem;
    min-height: 10rem;
    flex-grow: 1;

    /* border: 1px dashed yellow; */
  }

  .timer.hide {
    display: none;
  }

  .summary {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;

    padding: 1.5rem 5rem;
    /* background: linear-gradient(#1d4774 30%, #ddd); */
    color: #555;
    font-size: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    border-top: 1px dashed black;
    border-bottom: 1px dashed black;
    font-size: 2rem;
  }

  .summary-count {
    flex: 9;
  }
  .summary-time {
    flex: 9;
  }
  .summary-rank {
    background: #aaa;
    color: white;
    padding: 0 0.4rem;
    margin-right:2rem;
    border-radius: 10px;
    flex: 2;
    text-align: center;
  }
  .charts {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    /* background: #4474a8; */
    background: linear-gradient(mintcream 4%, #1d4774);
    color: black;
    font-family: "Courier New", Courier, monospace;

    padding: 2rem 0;

    margin: 0;
  }
  .plot {
    background: #ddd;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .stats {
    position: relative;
    background:#1D5775;
  }

  .plot {
    box-shadow: 5px 5px #777, -0.5em 0 0.4em rgb(117, 51, 73);
  }
  .gram {
    box-shadow: 5px 5px #777, -0.5em 0 0.4em rgb(117, 51, 73);
  }
}

@media (max-width: 20rem) {
  .scramble {
    /* background:green; */
    font-size: 2rem;
    height: 12rem;
  }

  .timer {
    /* background: red; */
    min-height: unset;
    font-size: 4rem;
    height: 100%;
    padding: 0;
  }
}

@media (max-height: 420px) {
  .list {
    height: 100%;
  }
}
