.menu {
  display:flex;
  justify-content: space-between;
  align-items: center;

  margin: 0;
  /* padding: 1rem 0; */
  /* border: 1px dotted #ccc; */
  height: 100%;
  align-self: center;
  justify-self: center;
}

.title {
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 2.3rem;
  flex-grow: 1;
  text-align: center;
}

#menuToggle{
  padding-top:0.3rem;
  padding-left:0.5rem;
  cursor: pointer;
}

#menuToggle span
{
  display: block;
  position: relative;
  width: 33px;
  height: 5px;
  margin: 5px 0;
  background: #dd0;
  border-radius: 3px;
}
#menuToggle:hover *{
  background: #ff0; 
  
}


/* Hide version number if width is less than ~ 1240px */
@media (max-width: 78rem) {
  .version {
    display: none;
  }
}

/* Hide cube, and center title is width is less than 860px */
@media (max-width: 860px) {
  .cube {
    display: none;
  }
  .title {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}
